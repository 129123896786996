.graphs-frame {
    margin: 20px auto;
    max-width: 1200px;
}

.graphs-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
}

.graphs-container img {
    width: 100%;
    max-width: 400px;
    height: auto;
    cursor: pointer;
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.lightbox img {
    max-width: 100vw; /* Use viewport width for better scaling */
    max-height: 100vh; /* Use viewport height for better scaling */
    width: 600px; /* Ensure the aspect ratio is maintained */
    height: 360px; /* Ensure the aspect ratio is maintained */
    box-shadow: 0 0 10px #fff;
}