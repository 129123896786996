.map-frame {
    margin: 20px auto;
    max-width: 1200px;
}

.map-container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-content {
    width: 100%;
    height: 500px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.marker {

    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

.maplibregl-popup {
    background: white !important;
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
}
.maplibregl-ctrl-top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.maplibregl-ctrl-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.maplibregl-ctrl-group > button {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 28px;
    height: 28px;
    margin-bottom: 4px;
    cursor: pointer;
}

.maplibregl-ctrl-group > button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.maplibregl-ctrl-group > button:active {
    background-color: rgba(0, 0, 0, 0.1);
}
