.current-conditions-frame {
    flex: 1; /* Take the remaining space */
    max-width: 600px; /* Add a maximum width */
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.current-conditions-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #000;
}

.current-conditions-details {
    margin-top: 1rem;
}

.current-conditions-icon {
    margin-top: 1rem;
}

.current-conditions-icon img {
    max-width: 100px;
    width: 100px;
}

.current-conditions p {
    margin: 0.5rem 0;
}

.current-conditions-link {
    margin-top: 1rem;
    color: blue;
    cursor: pointer;
}

@media (max-width: 768px) {
    .current-conditions-frame {
        width: 100%;
        margin-bottom: 1rem;
    }
}



