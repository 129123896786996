.App {
    text-align: center;
}

.App-header {
    padding: 0;
    color: white;
    position: relative;
}

.App-banner {
    width: 100%;
    height: auto;
}

.city-select {
    margin: 20px auto;
    width: 200px;
}

.main-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px;
}

main {
    padding: 20px;
}

img {
    max-width: 1053px;
}

#map {
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 0;
}

@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
        align-items: center;
    }

    .youtube-video-frame {
        margin-left: 0;
        margin-top: 1rem;
        width: 100%;
    }

    .current-conditions-frame {
        width: 100%;
    }
}
