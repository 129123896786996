.forecast-frame {
    margin: 20px auto;
    max-width: 1200px;
}

.forecast-container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forecast-cards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: center; /* Center the cards horizontally */
}

.forecast-card {
    flex: 0 0 auto;
    width: 150px;
    margin: 0 10px; /* Adjust margin to center cards */
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.forecast-card img {
    width: 50px;
    height: 50px;
}

.forecast-card p {
    margin: 5px 0;
}



@media (max-width: 768px) {
    .forecast-grid {
        flex-direction: column;
        align-items: center;
    }
}
