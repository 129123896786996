.youtube-video-frame {
    flex: 0 0 400px; /* Fixed width */
    margin-left: 1rem;
    box-sizing: border-box;
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.youtube-video {
    text-align: center;
}

.youtube-video iframe {
    width: 100%;
    height: 225px; /* Adjust height as needed */
    border: none;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .youtube-video-frame {
        flex: 1;
        width: 100%;
        height: auto;
    }

    .youtube-video iframe {
        height: 200px;
    }
}
